exports.components = {
  "component---src-pages-401-tsx": () => import("./../../../src/pages/401.tsx" /* webpackChunkName: "component---src-pages-401-tsx" */),
  "component---src-pages-403-tsx": () => import("./../../../src/pages/403.tsx" /* webpackChunkName: "component---src-pages-403-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-analytics-tsx": () => import("./../../../src/pages/admin/analytics.tsx" /* webpackChunkName: "component---src-pages-admin-analytics-tsx" */),
  "component---src-pages-admin-debts-tsx": () => import("./../../../src/pages/admin/debts.tsx" /* webpackChunkName: "component---src-pages-admin-debts-tsx" */),
  "component---src-pages-admin-payments-tsx": () => import("./../../../src/pages/admin/payments.tsx" /* webpackChunkName: "component---src-pages-admin-payments-tsx" */),
  "component---src-pages-admin-prices-tsx": () => import("./../../../src/pages/admin/prices.tsx" /* webpackChunkName: "component---src-pages-admin-prices-tsx" */),
  "component---src-pages-admin-requests-tsx": () => import("./../../../src/pages/admin/requests.tsx" /* webpackChunkName: "component---src-pages-admin-requests-tsx" */),
  "component---src-pages-admin-users-tsx": () => import("./../../../src/pages/admin/users.tsx" /* webpackChunkName: "component---src-pages-admin-users-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-email-confirmation-tsx": () => import("./../../../src/pages/email-confirmation.tsx" /* webpackChunkName: "component---src-pages-email-confirmation-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-password-confirm-tsx": () => import("./../../../src/pages/password/confirm.tsx" /* webpackChunkName: "component---src-pages-password-confirm-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password/reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-user-games-tsx": () => import("./../../../src/pages/user/games.tsx" /* webpackChunkName: "component---src-pages-user-games-tsx" */),
  "component---src-pages-user-payment-tsx": () => import("./../../../src/pages/user/payment.tsx" /* webpackChunkName: "component---src-pages-user-payment-tsx" */),
  "component---src-pages-user-payments-tsx": () => import("./../../../src/pages/user/payments.tsx" /* webpackChunkName: "component---src-pages-user-payments-tsx" */),
  "component---src-pages-user-profile-tsx": () => import("./../../../src/pages/user/profile.tsx" /* webpackChunkName: "component---src-pages-user-profile-tsx" */),
  "component---src-pages-user-settings-tsx": () => import("./../../../src/pages/user/settings.tsx" /* webpackChunkName: "component---src-pages-user-settings-tsx" */)
}

