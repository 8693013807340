import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import './style.scss';

const CookieBanner = () => {
	const [isBannerVisible, setBannerVisible] = useState(false);
	const [isSettingsVisible, setSettingsVisible] = useState(false);
	const [cookiePreferences, setCookiePreferences] = useState({
		performance: false,
		functionality: false,
		targeting: false,
	});

	useEffect(() => {
		const consent = localStorage.getItem('cookieConsent');

		if (!consent) {
			setBannerVisible(true);
		}
	}, []);

	const handleAcceptAll = () => {
		localStorage.setItem(
			'cookieConsent',
			JSON.stringify({
				performance: true,
				functionality: true,
				targeting: true,
			}),
		);
		setBannerVisible(false);
	};

	const handleDeclineNonEssential = () => {
		localStorage.setItem(
			'cookieConsent',
			JSON.stringify({
				performance: false,
				functionality: false,
				targeting: false,
			}),
		);
		setBannerVisible(false);
	};

	const handleSavePreferences = () => {
		localStorage.setItem('cookieConsent', JSON.stringify(cookiePreferences));
		setSettingsVisible(false);
		setBannerVisible(false);
	};

	const handleToggleSettings = () => {
		setSettingsVisible(!isSettingsVisible);
	};

	const handlePreferenceChange = (category, value) => {
		setCookiePreferences({
			...cookiePreferences,
			[category]: value,
		});
	};

	return (
		<>
			{isBannerVisible && (
				<div className="cb">
					{!isSettingsVisible ? (
						<div className="cb-message flex flex-col md:flex-row gap-5">
							<p className="text--1">
								We use cookies to enhance your experience and analyze site performance. You can accept
								all cookies, customize your cookie settings, or decline all cookies except those that
								are strictly necessary. For more information, please review our Cookie Policy.
							</p>
							<div className="cb-buttons flex flex-col gap-2 max-w-max text-blue-500">
								<button onClick={handleAcceptAll}>Accept All Cookies</button>
								<button onClick={handleToggleSettings}>Customize Settings</button>
								<button onClick={handleDeclineNonEssential}>Decline Non-Essential Cookies</button>
							</div>
						</div>
					) : (
						<div className="cb-settings">
							<h3 className="text--2 mb-2">Customize Cookie Settings</h3>
							<p className="text--1 mb-1">
								We use different types of cookies to optimize your experience on our Website. Please
								choose which categories of cookies you wish to allow:
							</p>
							<div className="cb-category text--1 mb-2">
								<h4 className="mb-1">Strictly Necessary Cookies</h4>
								<p>
									These cookies are essential for the website to function properly. They cannot be
									disabled as they enable core functionalities like security, network management, and
									accessibility.
								</p>
							</div>
							<div className="cb-category text--1 mb-2 flex items-center gap-6">
								<h4 className="mb-1">Performance Cookies</h4>
								<Checkbox
									checked={cookiePreferences.performance}
									onChange={(e) => handlePreferenceChange('performance', e.target.checked)}
									className="text--1 text-white"
								>
									Allow
								</Checkbox>
							</div>
							<div className="cb-category text--1 mb-2 flex items-center gap-6">
								<h4 className="mb-1">Functionality Cookies</h4>
								<Checkbox
									checked={cookiePreferences.functionality}
									onChange={(e) => handlePreferenceChange('functionality', e.target.checked)}
									className="text--1 text-white"
								>
									Allow
								</Checkbox>
							</div>
							<div className="cb-category text--1 mb-2 flex items-center gap-6">
								<h4 className="mb-1">Targeting or Marketing Cookies</h4>
								<Checkbox
									checked={cookiePreferences.targeting}
									onChange={(e) => handlePreferenceChange('targeting', e.target.checked)}
									className="text--1 text-white"
								>
									Allow
								</Checkbox>
							</div>
							<button
								onClick={handleSavePreferences}
								className="bg-white text-blue-500 whitespace-nowrap p-2 cursor-pointer rounded-xl"
							>
								Save Preferences
							</button>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default CookieBanner;
